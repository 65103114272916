
import PropTypes from 'prop-types';

import numeral from 'numeral';
import Check  from '@mui/icons-material/Check';
import { useGetLoansForApprovalQuery } from 'src/services/api';
import DashboardCard from './DashboardCard';
import { PendingActions } from '@mui/icons-material';




const PendingApproval = ({ className, ...rest }) => {
  
  const {data,isLoading,isFetching} = useGetLoansForApprovalQuery();
  
  const totalPrincipal = data?data.pageItems.reduce((a, b) => a + b.principal, 0):0;

  return (
    <DashboardCard
    href={(data && data.pageItems && data.pageItems.length)?`app/management/loans?tab=forApproval`:null}
    icon={<PendingActions color={totalPrincipal?"primary":"disabled"}/>}
    linkTitle={"Loans For Approval"}
    loading={isLoading || isFetching}
    primaryText={numeral(totalPrincipal).format('0,0.00')}
    secondaryText={`Loans For Approval`}
    caption={data && data.pageItems.length?`${data && data.pageItems.length} loans`:`No Loans for approval`}
    />
  );
};

PendingApproval.propTypes = {
  className: PropTypes.string,
  
};

export default PendingApproval;
