import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { Breadcrumbs, Grid, Link, Typography,Button,SvgIcon } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { UserPlus } from 'react-feather';



const Header = ({ className, ...rest }) => {
  
  
  /* const [isMenuOpen, setMenuOpen] = useState(false);
  const [timeRange, setTimeRange] = useState(timeRanges[2].text); */

  return (
    <Grid
      container
      spacing={3}
      justifyContent="space-between"
      
      {...rest}
    >
      <Grid item>
        <Typography color="textPrimary" variant='h5'>Overview</Typography>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{mt:1}}
        >
          <NextLink href="/app" legacyBehavior>
            <Link variant="subtitle2" color="textPrimary" underline='none'>
              Dashboard
            </Link>
          </NextLink>
          <Typography variant="subtitle2" color="textSecondary">
            Briefing
          </Typography>
        </Breadcrumbs>
        <Typography variant="h5" color="textPrimary">
          Here&apos;s what&apos;s happening
        </Typography>
      </Grid>
      <Grid item>
        <NextLink href="/app/management/clients/0/edit" passHref legacyBehavior>
          <Button
            color="primary"
            variant="contained"
            
            startIcon={
              <SvgIcon fontSize="small">
                <UserPlus />
              </SvgIcon>
            }
          >
            Onboard A New Client
          </Button>
        </NextLink>
      </Grid>
      
    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
