
import numeral from 'numeral';
import { useGetActiveLoansQuery } from 'src/services/api';
import Warning from '@mui/icons-material/Warning';
import DashboardCard from './DashboardCard';



const NonPerformingAssets = ({ className, activeLoans, ...rest }) => {
  
  const {data,isLoading,isFetching} = useGetActiveLoansQuery();
  
  const totalOutstanding = data?data.pageItems.filter(a => a.isNPA).reduce(
    (a, b) => a + b.summary.principalOverdue,
    0
  ):0;

  const principalOverdueNo = data?data.pageItems.filter(a => a.isNPA).length:0

  const primaryText = numeral(totalOutstanding).format('0,0.00')
  const secondaryText = `Non Performing Assets`
  const caption = `${principalOverdueNo} loans`

  return (
    <DashboardCard 
    primaryText={primaryText} 
    secondaryText={secondaryText} 
    icon={<Warning color={principalOverdueNo?"warning":"disabled"}/>} 
    caption={caption} 

    loading={isFetching || isLoading}
    href={principalOverdueNo?'/app/management/loans?tab=npa':null}
    />
  );
};

export default NonPerformingAssets;
