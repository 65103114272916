
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { useGetLoansForDisbursalQuery } from 'src/services/api';
import DashboardCard from './DashboardCard';
import { PendingActions } from '@mui/icons-material';


const PendingDisbursal = ({ className, loans, ...rest }) => {
  const {data, isLoading, isFetching} = useGetLoansForDisbursalQuery()
  const totalPrincipal = data?data.pageItems.reduce((a, b) => a + b.principal, 0):0;

  return (
    <DashboardCard
    href={(data && data.pageItems && data.pageItems.length)?`app/management/loans?tab=forDisbursal`:null}
    icon={<PendingActions color={totalPrincipal?"primary":"disabled"}/>}
    loading={isFetching || isLoading}
    linkTitle={"Loans For Disbursal"}
    primaryText={numeral(totalPrincipal).format('0,0.00')}
    secondaryText={`Loans For Disbursal`}
    caption={data && data.pageItems.length?`${data && data.pageItems.length} loans`:`No Loans for disbursal`}
    />
  );
};

PendingDisbursal.propTypes = {
  className: PropTypes.string,
  loans: PropTypes.array
};

export default PendingDisbursal;
