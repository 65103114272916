
import numeral from 'numeral';
import { useGetActiveLoansQuery } from 'src/services/api';
import Warning from '@mui/icons-material/Warning';
import DashboardCard from './DashboardCard';




const InterestOverdue = ({ className, activeLoans, ...rest }) => {
  
  const {data, isLoading,isFetching} = useGetActiveLoansQuery()

  const totalOutstanding = data?data.pageItems.reduce(
    (a, b) => a + b.summary.interestOverdue,
    0
  ):0;

  const principalOverdueNo = data?data.pageItems.filter(a => a.summary && a.summary.interestOverdue).length:0

  const primaryText = numeral(totalOutstanding).format('0,0.00')
  const secondaryText = `Interest Overdue`
  const caption = `${principalOverdueNo} loans`

  return (
    <DashboardCard 
    secondaryText={secondaryText} 
    primaryText={primaryText} 
    icon={<Warning color={principalOverdueNo?"warning":"disabled"}/>} 
    caption={caption} 
    href={principalOverdueNo?`/app/management/loans?tab=overdue`:null}
    loading={isLoading || isFetching}/>
  );
};



export default InterestOverdue;
