
import Users from "@mui/icons-material/People" 
import DashboardCard from './DashboardCard';



const Clients = ({ clients, label, linkTitle,href,loading }) => {
  

  return (
    <DashboardCard 
    icon={<Users color={clients && clients.length?"primary":"disabled"}/>} 
    secondaryText={label} 
    primaryText={clients?clients.length:0} 
    href={clients&& clients.length?href:null}
    linkTitle={linkTitle}
    loading={loading}

    />
  );
};

export default Clients;
