import numeral from 'numeral';
import { useGetActiveLoansQuery } from 'src/services/api';

import DashboardCard from './DashboardCard';
import Warning  from "@mui/icons-material/Warning";

const ActiveLoans = ({ className, activeLoans, ...rest }) => {
  const {data,isLoading,isFetching} = useGetActiveLoansQuery();
  const totalOutstanding = data?data.pageItems.reduce(
    (a, b) => a + b.summary.totalOverdue,
    0
  ):0;

  const principalOverdueNo = data?data.pageItems.filter(a => a.summary&&a.summary.totalOverdue).length:0
  const primaryText = numeral(totalOutstanding).format('0,0.00')
  const secondaryText = `Total Overdue`
  const caption = `${principalOverdueNo} loans`;

  return (
    <DashboardCard 
    primaryText={primaryText} 
    secondaryText={secondaryText} 
    icon={<Warning color={principalOverdueNo?"warning":"disabled"}/>} 
    caption={caption}
    href={principalOverdueNo?`/app/management/loans?tab=overdue`:null} 
    loading={isLoading || isFetching}/>
  );
};

export default ActiveLoans;
