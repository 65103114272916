
import numeral from 'numeral';
import { useGetActiveLoansQuery } from 'src/services/api';
import Warning from '@mui/icons-material/Warning';
import DashboardCard from './DashboardCard';



const OverdueRatio = ({ className, activeLoans, ...rest }) => {
  
  const {data,isLoading,isFetching} = useGetActiveLoansQuery();
  const totalPrincipalOutstanding = data?data.pageItems.reduce(
    (a, b) => a + b.summary.principalOutstanding,
    0
  ):0;
  const totalPrincipalOverdue = data?data.pageItems.reduce(
    (a, b) => a + b.summary.principalOverdue,
    0
  ):0;

  const ratio = (totalPrincipalOverdue/totalPrincipalOutstanding)*100

  



  const principalOverdueNo = data?data.pageItems.filter(a => a.summary && a.summary.principalOverdue).length:0

  const primaryText = parseFloat(ratio)?`${Math.round(ratio)} %`:"N/A"
  const secondaryText = `Portfolio At Risk`
  const caption = `${numeral(totalPrincipalOverdue).format('0,0.00')} / ${numeral(totalPrincipalOutstanding).format('0,0.00')} `

  return (
    <DashboardCard 
    primaryText={primaryText} 
    secondaryText={secondaryText} 
    icon={<Warning color={principalOverdueNo?"warning":"disabled"}/>} 
    caption={caption}
    href={principalOverdueNo?`/app/management/loans?tab=overdue`:null} 
    loading={isLoading || isFetching}/>
  );
};

export default OverdueRatio;
