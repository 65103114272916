import numeral from 'numeral';
import { useGetActiveLoansQuery } from 'src/services/api';

import DashboardCard from './DashboardCard';
import { isThisMonth } from 'date-fns';
import { SendAndArchive } from '@mui/icons-material';

const DisbursedThisMonth = ({ className, activeLoans, ...rest }) => {
  const {data,isLoading,isFetching} = useGetActiveLoansQuery();
  const disbursedToday = data?data.pageItems.filter(a => a.timeline&&isThisMonth(new Date(a.timeline.actualDisbursementDate))):[]
  const totalDisbursedToday = disbursedToday.reduce((a,b)=> a + b.principal,0)

  const disbursedTodayNo = disbursedToday.length
  const primaryText = numeral(totalDisbursedToday).format('0,0.00')
  const secondaryText = `Disbursed This Month`
  const caption = `${disbursedTodayNo} loan(s)`;

  return (
    <DashboardCard 
    primaryText={primaryText} 
    secondaryText={secondaryText} 
    icon={<SendAndArchive color={disbursedToday?"success":"disabled"}/>} 
    caption={caption}
    href={disbursedTodayNo?`/app/management/loans?tab=active`:null} 
    loading={isLoading || isFetching}/>
  );
};

export default DisbursedThisMonth;
