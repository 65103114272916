import { Container, Grid,Box } from '@mui/material';
import Header from './Header';

import ActiveLoans from './ActiveLoans';
import { isThisMonth, isToday } from 'date-fns';
import InterestThisMonth from './InterestThisMonth';
import PendingApproval from './PendingApproval';
import PendingDisbursal from './PendingDisbursal';
import Clients from './AllClients';

import { useGetClientsQuery } from 'src/services/api';
import PrincipalOverdue from './PrincipalOverdue';
import PrincipalOutstanding from './PrincipalOutstanding';
import InterestOutstanding from './InterestOutstanding';
import TotalOutstanding from './TotalOutstanding';
import InterestOverdue from './InterestOverdue';
import Head from 'next/head';
import useSettings from 'src/hooks/useSettings';
import NonPerformingAssets from './NPALoans';
import DisbursedToday from './DisbursedToday';
import DisbursedThisMonth from './DisbursedThisMonth';
import OverdueRatio from './OverdueRatio';



const DashboardView = () => {
  const {settings} = useSettings()
  const {data:myClients,isLoading,isFetching} = useGetClientsQuery();
  
  return (
      <>
      <Head><title>BANKAYO: Overview</title></Head>
      <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 8,
      }}
    >
      <Container maxWidth={settings.compact ? "xl" : false}>
        <Header />
        <Grid container spacing={2} paddingTop={1}>
          <Grid item lg={3} sm={6} xs={12}>
            <Clients 
            clients={myClients?myClients.pageItems:[]} 
            label="All Customers"
            href={`/app/management/clients?tab=all`}
            loading={isFetching || isLoading}
            linkTitle={"See All Customers"} />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Clients
              clients={(myClients?myClients.pageItems:[]).filter((a) => a.active)}
              label="Active Customers"
              href={`/app/management/clients?tab=active`}
              linkTitle={"See All Active Customers"}
              loading={isFetching || isLoading}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Clients
              clients={(myClients?myClients.pageItems:[]).filter((a) => !a.active)}
              label="Inactive Customers"
              href={`/app/management/clients?tab=inactive`}
              linkTitle={"See All Inactive Customers"}
              loading={isFetching || isLoading}
            />
          </Grid>

          <Grid item lg={3} sm={6} xs={12}>
            <Clients
              clients={(myClients?myClients.pageItems:[]).filter((a) =>
                isThisMonth(new Date(a.activationDate))
              )}
              label="New Customers This Month"
              href={`/app/management/clients?tab=new`}
              linkTitle={"See All New Customers"}
              loading={isFetching || isLoading}
              
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <PrincipalOutstanding/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <InterestOutstanding/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <TotalOutstanding/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <InterestThisMonth />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <PrincipalOverdue />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <InterestOverdue />
          </Grid>
          
          <Grid item lg={3} sm={6} xs={12}>
            <ActiveLoans />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <NonPerformingAssets/>
          </Grid>
          

          
          <Grid item lg={3} sm={6} xs={12}>
            <PendingApproval  />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <PendingDisbursal />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <DisbursedToday />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <DisbursedThisMonth />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Clients
              clients={(myClients?myClients.pageItems:[]).filter((a) =>
                isToday(new Date(a.activationDate))
              )}
              label="New Clients Today"
              href={`/app/management/clients?tab=new`}
              linkTitle={"See All New Customers"}
              loading={isFetching || isLoading}
              
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <OverdueRatio />
          </Grid>
        </Grid>
      </Container>
      </Box>
    </>
  );
};

export default DashboardView;
