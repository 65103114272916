

import numeral from 'numeral';
import { useGetActiveLoansQuery } from 'src/services/api';
import DashboardCard from './DashboardCard';
import { Money } from '@mui/icons-material';



const TotalOutstanding = ({ className, activeLoans, ...rest }) => {
  
  const {data, isFetching,isLoading} = useGetActiveLoansQuery();
  const totalOutstanding = data?data.pageItems.reduce(
    (a, b) => a + b.summary.totalOutstanding,0
  ):0;

  const totalOutstandingNo = data?data.pageItems.filter(a => a.summary && a.summary.totalOutstanding).length:0
  const secondaryText=`TOTAL Outstanding`;
  const primaryText = numeral(totalOutstanding).format('0,0.00');
  const caption = `${totalOutstandingNo} loans`

  return (
    <DashboardCard 
    primaryText={primaryText} 
    icon={<Money color={totalOutstandingNo?"primary":"disabled"}/>}
    secondaryText={secondaryText} 
    caption={caption} 
    href={totalOutstandingNo?`/app/management/loans`:null}
    loading={isLoading || isFetching} />
  );
};

export default TotalOutstanding;
