
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import numeral from 'numeral';
import { useGetActiveLoansQuery } from 'src/services/api';
import { isThisMonth } from 'date-fns';
import DashboardCard from './DashboardCard';



const InterestThisMonth = ({ className, loans, ...rest }) => {
  
  const {data, isLoading,isFetching} =useGetActiveLoansQuery();
  const interest = data?data.pageItems.filter(a =>isThisMonth(new Date(a.timeline.actualDisbursementDate))).reduce((a, b) => a + b.summary.interestCharged, 0):0;
  const interestThisMonthNo = data?data.pageItems.filter(a =>isThisMonth(new Date(a.timeline.actualDisbursementDate))).length:0;
  const primaryText=numeral(interest).format('0,0.00');
  const secondaryText = `Interest this month`
  const caption = interestThisMonthNo? `${interestThisMonthNo} loans`:`No interest this month`
  return (
    <DashboardCard 
    primaryText={primaryText} 
    secondaryText={secondaryText} 
    icon={<AttachMoneyIcon color={interestThisMonthNo?"success":"disabled"}/>} 
    caption={caption}
    href={interestThisMonthNo?`/app/management/loans`:null} 
    loading={isLoading || isFetching}/>
  );
};

export default InterestThisMonth;
