

import numeral from 'numeral';
import { useGetActiveLoansQuery } from 'src/services/api';
import DashboardCard from './DashboardCard';
import { Money } from '@mui/icons-material';




const PrincipalOutstanding = () => {
  
  const {data, isLoading, isFetching} = useGetActiveLoansQuery();
  const totalOutstanding = data?data.pageItems.reduce(
    (a, b) => a + b.summary.principalOutstanding,0
  ):0;

  const principalOutstandingNo = data?data.pageItems.filter(a => a.summary && a.summary.principalOutstanding).length:0;
  const secondaryText = `PRINCIPAL Outstanding`;
  const primaryText = numeral(totalOutstanding).format('0,0.00');
  const caption = `${principalOutstandingNo} loans`
  
  return (
    <DashboardCard 
    primaryText={primaryText} 
    secondaryText={secondaryText} 
    caption={caption}
    icon={<Money color={principalOutstandingNo?"primary":"disabled"} />}
    href={principalOutstandingNo?`/app/management/loans`:null} 
    loading={isLoading || isFetching} />
  );
};

export default PrincipalOutstanding;
