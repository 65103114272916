import { Box, Card,Typography, Skeleton, CardContent } from "@mui/material";

import NextLink from "next/link";

const DashboardCard = ({primaryText,secondaryText,icon, caption, href,tooltip,linkTitle,loading}) =>{

  return (
    <Card >
        <CardContent component={href? NextLink:null} href={href} sx={{textDecoration:"none"}}>
        {loading? <Skeleton/>:(<>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            p: 2
          }}
        >
          <div>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              {secondaryText}
            </Typography>
            <Typography
              color="textPrimary"
              sx={{ mt: 1 }}
              variant="h5"
            >
              {primaryText}
            </Typography>
            {caption? <Typography
            color="textSecondary"
            
            variant="caption"
          >
            {caption}
          </Typography>:null}
          </div>
          {icon?icon:<></>}
          
        </Box>
        
        </>)}
        </CardContent>
      </Card>
  );

}

export default DashboardCard;
