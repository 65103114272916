import numeral from 'numeral';
import { useGetActiveLoansQuery } from 'src/services/api';

import DashboardCard from './DashboardCard';
import { Money } from '@mui/icons-material';




const InterestOutstanding = ({ className, activeLoans, ...rest }) => {
  
  const {data, isLoading, isFetching} = useGetActiveLoansQuery()

  const totalOutstanding = data?data.pageItems.reduce(
    (a, b) => a + b.summary.interestOutstanding,0
  ):0;

  const interestOutstandingNo = data?data.pageItems.filter(a => a.summary && a.summary.interestOutstanding).length:0

  const secondaryText = `Interest Outstanding`;
  const primaryText = numeral(totalOutstanding).format('0,0.00');
  const caption = `${interestOutstandingNo} loans`

  return (
    <DashboardCard 
    primaryText={primaryText} 
    secondaryText={secondaryText} 
    caption={caption} 
    icon={<Money color={interestOutstandingNo?"primary":"disabled"}/>}
    href={interestOutstandingNo?`/app/management/loans`:null}
    loading={isLoading || isFetching}
    />
  );
};



export default InterestOutstanding;
